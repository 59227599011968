/* Phông chữ chung */
.skill-container {
    text-align: center;
    padding: 50px 20px;

    font-family: 'Arial', sans-serif;
  }
  
  .skill-title {
    font-size: 32px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    font-family: "Cormorant Garamond", serif;
  }
  
  .skill-description {
    font-size: 18px;
    color: #555;
    margin-bottom: 30px;
  }
  
  /* Grid hiển thị các skill */
  .skill-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 3 card trên mỗi hàng cho màn hình lớn */
    gap: 20px;
    justify-content: center;
    align-items: center;
   width: 100%;
    margin: 0 auto;
  
  }
  
  /* Card từng skill */
  .skill-card {
    background: rgb(255, 255, 255);
    border: 2px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .skill-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Icon của từng skill */
  .skill-icon {
    font-size: 50px;
    margin-bottom: 10px;
  }
  
  .html { color: #E44D26; }
  .css { color: #1572B6; }
  .bootstrap { color: #7952B3; }
  .nextjs { color: #000000; }
  .react { color: #61DAFB; }
  .nodejs { color: #3C873A; }
  .mysql { color: #00758F; }
  .javascript { color: #F7DF1E; }
  .github { color: #181717; }
  
  .skill-card span {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  /* Responsive: khi màn hình nhỏ hơn 768px */
@media (max-width: 768px) {
    .skill-grid {
        grid-template-columns: repeat(3, 1fr); /* 2 card trên mỗi hàng */
    }
}

/* Responsive: khi màn hình nhỏ hơn 480px */
@media (max-width: 480px) {
    .skill-grid {
        grid-template-columns: repeat(3, 1fr); /* 2 card trên mỗi hàng */
    }
    .skill-title {
        font-size: 27px;
        font-weight: bold;
        color: #333;
        margin-bottom: 10px;
        font-family: "Cormorant Garamond", serif;
      }
}