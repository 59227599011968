/* General reset and styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

body {
  background-color: #f4f7fb;
  color: #333;
  font-size: 16px;
  line-height: 1.6;
  overflow-x: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.navbar {
  position: fixed; /* Cố định trên cùng */
  width: 100%;
  top: 0;
  z-index: 100;
  background-color: #25262f;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 100px;
  color: white;
}

.nav-left {
  display: flex;
  align-items: center;
}

.logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50%;
}

.site-name {
  font-size: 20px;
  font-weight: bold;
}

/* Menu trên PC */
.nav-right {
  display: flex;
  gap: 15px;
}

.navbar .nav-link {
  position: relative;
  background: none;
  border: none;
  color: #d4d4d4;
  font-size: 1.1rem;
  margin: 0 20px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  
}

/* Khi hover vào menu */
.nav-right .nav-link:hover {
  color: #f05454; /* Đổi màu chữ */
}
/* Hiệu ứng underline */
.nav-right .nav-link::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  width: 0;
  height: 3px;
  background-color: #f05454; /* Màu của underline */
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

/* Khi hover vào thì underline mở rộng */
.nav-right .nav-link:hover::after {
  width: 100%;
  left: 0;
}
/* Khi menu đang active */
.nav-right .nav-link.active {
  color: #f05454; /* Đổi màu chữ */
  font-weight: bold;
}

/* Khi menu đang active có underline */
.nav-right .nav-link.active::after {
  width: 100%;
  left: 0;
}
.menu-icon {
  display: none;
  font-size: 28px;
  cursor: pointer;
}
.header {
  height: 100vh;
 
  display: flex;
 
 
  color: white;
  position: relative;
}

.header h1 {
  font-size: 4rem;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.header p {
  font-size: 1.2rem;
  margin-top: 20px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.section {
  padding: 100px 0px;
 width: 86%;
  margin: 0 auto;
}

.section h2 {
  font-size: 2.5rem;
  color: #2e3a59;
  margin-bottom: 30px;
  text-align: center;
}

.section p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
  text-align: center;
}

.card-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 40px;
}

.card {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 30%;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
}

.card img {
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.card h3 {
  margin-top: 15px;
  font-size: 1.5rem;
  color: #333;
}

.card p {
  margin-top: 10px;
  color: #777;
}

footer {
  background-color: #2e3a59;
  color: #fff;
  text-align: center;
  padding: 20px;
  margin-top: 50px;
  font-size: 0.9rem;
}

footer a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

footer a:hover {
  color: #007bff;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {

  .nav-right {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 10px;
    background-color: #25262f;
    width: 200px;
    border-radius: 5px;
   
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  
  }
.nav-left{
  margin-left: -80px;
}
  .nav-right.active {
    display: flex;
  }

  .nav-link {
    display: block;
    padding: 10px 15px;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  /* Hiện nút menu hamburger */
  .menu-icon {
    display: block; /* Hiện icon */
    position: absolute;
    top: 30px; /* Điều chỉnh khoảng cách từ trên xuống */
    right: 25px; /* Cách lề phải 10px */
  
  }

  .section {
    padding: 40px 20px;
  }

  .card-container {
    flex-direction: column;
    gap: 30px;
  }

  .card {
    width: 100%;
  }

  .header h1 {
    font-size: 3rem;
  }

  .header p {
    font-size: 1rem;
  }
}
