.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Mờ nền */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  /* Khi modal active, hiển thị overlay */
  .modal-overlay.active {
    opacity: 1;
    visibility: visible;
  }
  /* Khi modal active, nó trượt lên */
  .modal-overlay.active .modal-content {
    transform: translateY(0);
  }
  
  /* Nút đóng modal */
  .btn-close {
    z-index: 10000; /* Đảm bảo modal luôn nổi trên tất cả */
    position: absolute; 
    top: 87px;
    right: 150px;
    width: 35px;
    height: 35px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1001; /* Đảm bảo nằm trên modal */
    transition: transform 0.2s ease, background-color 0.3s ease;
  }
  
  .btn-close:hover {
    background-color: rgb(253, 43, 43);
    transform: scale(1.1);
  }
  
  /* Nội dung modal */
  .modal-content {
    position: relative;
    background: #ffffff;
    width: 85%;
    max-width: 90%;
    margin-left: -20px;
    height: 630px;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    overflow-y: auto;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3); /* Hiệu ứng đổ bóng */
    transform: translateY(20px);
    transition: transform 0.3s ease-in-out;
  }
 .modal-details{
    width: 100%;
    padding: 10px;
   
  }
 .modalblok1{
  display: flex;
  
 }
 .modalblok1 p{
  font-size: 20px;
  margin-top: 4px;
  margin-left: 5px;
 }
 .modalblok1 h2{
  margin-left: 10px;
 }
  /* Media Queries for Mobile */
@media only screen and (max-width: 768px) {
  .modal-content {
    width: 90%;
    max-height: 650px;
    padding: 15px;
    margin-left: 1px;
  }

  .btn-close {
    top: 100px;
    right: 15px;
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
  .modalblok1 p {
    font-size: 16px;
  
  }

  .modalblok1 h2 {
    font-size: 18px;
    margin-left: 20px;
  }
}

/* Media Queries for smaller screens (mobile) */
/* Media Queries for smaller screens (mobile) */

  
  

