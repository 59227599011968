.experience-container {
    background-color: #1c1c24; /* Màu nền tối */
    padding: 50px 0;
    display: flex;
    justify-content: space-around;
   height: 300px;
  }
  
  .experience-item {
    display: flex;
    margin-top: 30px;
  }
  
  .numberexperience {
    font-size: 80px;
    font-weight: bold;
    color: #d9534f; /* Màu chữ đỏ */
     
   
  }
  
  .textexperience {
    font-size: 20px;
    font-weight: bold;
    color: white;
    margin-left: 10px;
    margin-top: 30px;
 
  }
  
  .textexperience p {
    margin: 0;
  }
  @media (max-width: 768px) {
    .experience-container {
        background-color: #1c1c24; /* Màu nền tối */
        padding: 30px 0;
        display: flex;
        justify-content: space-around;
       height: 200px;
      }
    .textexperience {
        font-size: 10px;
        font-weight: bold;
        color: white;
        margin-left: 10px;
        margin-top: 30px;
     
      }
      .numberexperience {
        font-size: 60px;
        font-weight: bold;
        color: #d9534f; /* Màu chữ đỏ */
         
       
      }
  }